import React, { useEffect } from "react";
import { uniq } from "lodash";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import { MyCollectionsProvider } from "../../../contexts/MyCollectionsContext/MyCollectionsContext";
import Breadcrumbs, { Breadcrumb } from "../../composites/v2/Breadcrumbs/Breadcrumbs";
import styled from "styled-components";
import Box from "../../elements/v2/Box/Box";
import { GetListing } from "./queries/types/GetListing";
import ProductImageCarousel from "./components/ProductImageCarousel/ProductImageCarousel";
import Description from "./components/Description/Description";
import Details from "./components/Details/Details";
import ListingTitle from "./components/ListingTitle/ListingTitle";
import ListingPrice from "./components/ListingPrice/ListingPrice";
import RetailPrice from "./components/RetailPrice/RetailPrice";
import Size from "./components/Size/Size";
import Colour from "./components/Colour/Colour";
import SellerProfile from "./components/SellerProfile/SellerProfile";
import StartTransactionFlow from "./components/StartTransactionFlow/StartTransactionFlow";
import RentalInfo from "./components/RentalInfo/RentalInfo";
import ListingActions from "./components/ListingActions/ListingActions";
import { breakpoints } from "../../../utils/styledHelpers";
import ProductAuthenticationText, {
  ProductAuthenticationEnum,
} from "./components/ProductAuthenticationText/ProductAuthenticationText";
import BuyersPromise from "./components/BuyersPromise/BuyersPromise";
import PercentOffChip from "../../elements/PercentOffChip";
import ProductRecommendations from "./components/ProductRecommendations/ProductRecommendations";
import AccordionShowHide from "../../composites/v2/AccordionShowHide/AccordionShowHide";
import isSSR from "../../../utils/isSSR";
import { addBreadcrumbsStructure } from "../../../utils/breadcrumbsHelper";
import { HOCK_YOUR_FROCKS_USERNAME } from "../../../constants/person";

export type ListingRoutes = {
  delete: string;
  undoSold: string;
  undoDonate: string;
  donate: string;
  star: string;
  reject: string;
  approve: string;
  editOld: string;
  editProduct: string;
  editSale: string;
  editRental: string;
  close: string;
  brandPath: string;
};

export type AdminProps = {
  isApproved: boolean | null;
  isApprovalPending: boolean | null;
  isApprovalRejected: boolean | null;
  sellerCommissionPercent: number | null;
  brandApprovalNeeded: boolean | null;
};

type Props = {
  id: number;
  getListingProps: GetListing;
  routes: ListingRoutes;
  adminProps: AdminProps;
  isAdmin: boolean;
  breadcrumbs: Breadcrumb[];
  googleMapsApiKey: string;
  latitude?: string;
  longitude?: string;
  productAuthenticationType: ProductAuthenticationEnum;
  listingPublishedByAirrobe: boolean;
  showBuyButton: boolean;
  showMakeAnOfferButton: boolean;
  countryCode: string;
};

const MAX_RECENTLY_VIEWED_LISTINGS = 10;
export const RECENTLY_VIEWED_LISTINGS_LOCAL_STORE_KEY = "ar-recently-viewed-listing-ids";

function ListingPage({
  getListingProps,
  isAdmin,
  routes,
  adminProps,
  breadcrumbs,
  googleMapsApiKey,
  latitude,
  longitude,
  productAuthenticationType,
  listingPublishedByAirrobe,
  showBuyButton,
  showMakeAnOfferButton,
  countryCode,
}: Props) {
  const { listing } = getListingProps;
  const showPriceReduced =
    listing?.isPriceReduced && listing.author.username !== HOCK_YOUR_FROCKS_USERNAME;

  useEffect(() => {
    addBreadcrumbsStructure(breadcrumbs, { label: listing.title, path: listing.url });
  }, [breadcrumbs, listing]);

  return (
    <MyCollectionsProvider>
      <BreadcrumbsContainer my={3}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </BreadcrumbsContainer>
      <SplitSection mb={4} mt={1}>
        <LeftColumn>
          <ProductImageCarousel listing={listing} />
        </LeftColumn>
        <RightColumn>
          <ListingTitle mb={3} listing={listing} routes={routes} />
          <ListingActions
            isAdmin={isAdmin}
            routes={routes}
            adminProps={adminProps}
            listing={listing}
          />
          <PriceBox>
            <ListingPrice listing={listing} showPriceReduced={showPriceReduced} />
            <PercentOffChipStyled
              percentOff={listing.priceReducedPercentage}
              showPriceReduced={showPriceReduced}
            />
          </PriceBox>
          <RetailPrice listing={listing} />
          <RentalInfo mt={3} listing={listing} />
          <Size mt={3} listing={listing} countryCode={countryCode} />
          <Colour mt={3} listing={listing} />
          <StartTransactionFlow
            mt={3}
            mb={1}
            isAdmin={isAdmin}
            listing={listing}
            googleMapsApiKey={googleMapsApiKey}
            listingPublishedByAirrobe={listingPublishedByAirrobe}
            showBuyButton={showBuyButton}
            showMakeAnOfferButton={showMakeAnOfferButton}
          />
          <ProductAuthenticationText productAuthenticationType={productAuthenticationType} />
          <BuyersPromise mt={2} listing={listing} />
        </RightColumn>
      </SplitSection>
      <Section mt={3}>
        <MoreDetailsAccordion title="More details">
          <Description>{listing.description}</Description>
          <Details listing={listing} />
          <SellerProfile my={2} listing={listing} />
        </MoreDetailsAccordion>
      </Section>
      <Section>
        <ProductRecommendations listing={listing} latitude={latitude} longitude={longitude} />
      </Section>
    </MyCollectionsProvider>
  );
}

const Section = styled(Box)<{ $grey?: boolean }>`
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  ${({ $grey }) =>
    $grey &&
    `
    background: #f6f6f6;
  `}
`;

const BreadcrumbsContainer = styled(Section)`
  ${breakpoints.down("tablet")} {
    display: none;
  }
`;

const SplitSection = styled(Section)`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  ${breakpoints.down("tablet")} {
    flex-direction: column;
  }
`;

const LeftColumn = styled(Box)`
  flex-grow: 1;
  overflow: hidden;
`;

const RightColumn = styled(Box)`
  width: calc(50% - 12px);
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  min-width: 435px;

  ${breakpoints.down("tablet")} {
    width: 100%;
    min-width: initial;
  }
`;

const MoreDetailsAccordion = styled(AccordionShowHide)`
  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding: 0;
  }

  ${breakpoints.up("tablet")} {
    .MuiAccordionSummary-root {
      display: none;
    }
    .MuiCollapse-root {
      height: auto;
      visibility: visible;
    }
  }
`;

const PriceBox = styled(Box)`
  display: flex;
`;

const PercentOffChipStyled = styled(PercentOffChip)`
  margin-left: 8px;
  padding: 8px 0 7px 0;
  height: 23px;

  &.MuiChip-root {
    .MuiChip-label {
      padding-right: 7px;
    }
  }
`;

type Args = {
  topbar: any; // This will remain a black box to typescript until we rewrite it
  props: Props;
};

const mapEntryProps = ({ topbar, props }: Args) => {
  if (!isSSR()) {
    const currentListingId = props.id;

    const oldRecentlyViewedListingIds =
      JSON.parse(localStorage.getItem(RECENTLY_VIEWED_LISTINGS_LOCAL_STORE_KEY)) || [];

    // Puts current listing id at the start of the array, removes duplicates, then limits the array size to MAX_RECENTLY_VIEWED_LISTINGS
    const newRecentlyViewedListingIds = uniq([
      currentListingId,
      ...oldRecentlyViewedListingIds,
    ]).slice(0, MAX_RECENTLY_VIEWED_LISTINGS);
    localStorage.setItem(
      RECENTLY_VIEWED_LISTINGS_LOCAL_STORE_KEY,
      JSON.stringify(newRecentlyViewedListingIds)
    );
  }

  return {
    pageProps: {
      topbarProps: topbar,
    },
    componentProps: props,
  };
};

export default createEntryPage(mapEntryProps)(ListingPage);
