import React from "react";
import { Chip } from "@material-ui/core";
import styled from "styled-components";
import { formatMoney } from "../../../../utils/numbers";
import SmartImage from "../../SmartImage/SmartImage";
import Typography from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import ListingCollectionsDropdown from "../../../widgets/ListingCollectionsDropdown/ListingCollectionsDropdown";
import Card from "../../../elements/v2/Card/Card";
import { H6 } from "../../../elements/v2/Typography/Heading";
import Box from "../../../elements/v2/Box/Box";
import { ListingData } from "../../../../utils/listings";
import { ListingCardFragment } from "../../../../graphql/fragments/types/ListingCardFragment";
import { extractListingSizeText, removeSizeText } from "../../../../utils/listingHelper";
import LinkBox from "../../../elements/v2/Box/LinkBox";
import { getBrandPathByKeyOrName } from "../../../../utils/paths";
import PercentOffChip from "../../../elements/PercentOffChip";
import { HOCK_YOUR_FROCKS_USERNAME } from "../../../../constants/person";

export interface Props {
  listing: Partial<ListingCardFragment> | ListingData;
  hideFavouriteIcon?: boolean;
  handleClick?: () => void;
  countryCode?: string;
}

function ListingCard({ listing, hideFavouriteIcon, handleClick, countryCode, ...props }: Props) {
  const href = listing.path || listing.url;
  const showPriceReduced = listing.isPriceReduced && listing.author !== HOCK_YOUR_FROCKS_USERNAME;

  const sizeText = extractListingSizeText(listing, countryCode);
  const brandHref =
    "brand_key" in listing ? getBrandPathByKeyOrName(listing.brand_key, listing.brand) : "";

  return (
    <StyledCard href={href} onClick={handleClick} {...props}>
      <SquareWrapper $sold={Boolean(listing.sold)}>
        <Box pb="100%">
          <StyledSmartImage imageUrl={listing.previewUrl} />
        </Box>
      </SquareWrapper>
      {!hideFavouriteIcon && <FavouriteIcon listing={listing} />}
      <Info $sold={Boolean(listing.sold)} p={2}>
        <LinkBoxHover href={brandHref}>
          <Brand>{listing.brand}</Brand>
        </LinkBoxHover>
        <Title>{removeSizeText(listing.title)}</Title>
        {sizeText && <Size>{sizeText}</Size>}
        {listing.rrp && <Rrp>{`Retail price: ${formatMoney(listing.rrp).replace(".00", "")}`}</Rrp>}
        <PricingWrapper>
          <Price>
            {showPriceReduced && (
              <OriginalPrice>{formatMoney(listing.originalPrice)}</OriginalPrice>
            )}
            {listing.price ? (
              <>
                <CurrentPrice $reduced={showPriceReduced}>
                  {formatMoney(listing.price)}
                </CurrentPrice>
                {listing.unitType && <Per>{`/ ${listing.unitType}`}</Per>}
              </>
            ) : (
              "–"
            )}
          </Price>
          <PercentOffChip
            percentOff={listing.priceReducedPercentage}
            showPriceReduced={showPriceReduced}
          />
        </PricingWrapper>
      </Info>
      {listing.sold && <SoldChip />}
    </StyledCard>
  );
}

export default ListingCard;

const StyledCard = styled(Card).attrs({
  className: "t-listing",
})`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  background-color: white;
  height: 100%;
`;

const SquareWrapper = styled(Box)<{ $sold?: boolean }>`
  position: relative;
  width: 100%;
  ${({ $sold }) =>
    $sold &&
    `
    opacity: 0.5;
  `}
`;

const StyledSmartImage = styled(SmartImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
`;

const Info = styled(FlexBox)<{ $sold?: boolean }>`
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  ${({ $sold }) =>
    $sold &&
    `
    opacity: 0.5;
  `}
`;

const Brand = styled(H6)`
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
  line-height: 21px;
`;

const Title = styled(Typography)`
  flex-grow: 1;
  margin-bottom: 16px;
  line-height: 1.4;
  font-weight: 300;
`;

const PricingWrapper = styled(FlexBox)`
  gap: 6px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Price = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  line-height: 1;
`;

const PercentOff = styled.span`
  font-weight: 500;
`;

const OriginalPrice = styled.span`
  font-weight: 500;
  text-decoration: line-through;
`;

const CurrentPrice = styled.span<{ $reduced?: boolean }>`
  font-weight: 600;
  ${({ $reduced }) => $reduced && `color: red`}
`;

const Per = styled.span`
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
`;

const Size = styled(Typography)`
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
  font-size: 0.95rem;
`;

const Rrp = styled(Typography)`
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
  font-size: 0.95rem;
`;

const SoldChip = styled(Chip).attrs({
  label: "SOLD",
})`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const FavouriteIcon = styled(ListingCollectionsDropdown)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 23px;
  }
`;

const LinkBoxHover = styled(LinkBox)`
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;
